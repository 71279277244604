/** @format */

export const envBackgroundColor = () => {
	const location = window.location.href
	let bgcolor =
		location.indexOf('localhost') > 0 || location.indexOf('int.smartengo-backbone.aws.smartdev.vallourec.com') > 0
			? '#d9ecd0'
			: location.indexOf('qual.smartengo-backbone.aws.smartdev.vallourec.com') > 0
			? '#b3d5e0'
			: ''
	console.log(bgcolor)
	return bgcolor
}
