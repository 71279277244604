const XLSX = require('xlsx')

/**
 *
 * @param {File} acceptedFile The file which the user intend to upload
 */
export const xlsxValidator = (data) => {
    let worksheet = null;
    if (data.stackValidation) {
        if (Array.isArray(data.errorValidation)) {
            const errors = [];
            data.errorValidation.map((error) => errors.push(
                            { Location: 'At line: ' + error.row , Error: capitalizeFirstLetter(error.message) }));
            worksheet = XLSX.utils.json_to_sheet(errors);
        } else {
            worksheet = XLSX.utils.json_to_sheet( [{ Error: capitalizeFirstLetter(data.errorValidation)}]);
        }
    } else {
        worksheet = XLSX.utils.json_to_sheet(data.errorValidation);
    }

    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const options = {bookType: 'xlsx', type: 'array'};
    XLSX.writeFile(workbook, 'inventory_error_list.xlsx', options);

}

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

