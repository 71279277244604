/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import PrivateRoute from './containers/router/PrivateRoute'

import RedirectTo from './pages/RedirectTo'
import Home from './pages/Home'

import ViewLoadData from './pages/ViewLoadData'

import OctgHome from './pages/OCTGHome'
import OctgViewActualData from './pages/OctgViewActualData'
import OctgViewNominalData from './pages/OctgViewNominalData'
import OctgUploadActualData from './pages/OctgUploadActualData'
import OctgUploadNominalData from './pages/OctgUploadNominalData'
import PlppData from './pages/PlppData'
import ProjectAndMaterialData from './pages/ProjectAndMaterialData'

import Header from './components/layout/Header'
import { envBackgroundColor } from './utils/enviromentbgcolor'

const Router = () => {
	return (
		<div style={{ backgroundColor: envBackgroundColor(), height: '100vh' }}>
			<BrowserRouter>
				<Header />
				<Switch>
					<Route path="/redirect/:to" exact>
						<RedirectTo />
					</Route>
					<PrivateRoute path="/">
						<Switch>
							<PrivateRoute exact path="/">
								<Home />
							</PrivateRoute>
							<PrivateRoute exact path="/load/view">
								<ViewLoadData />
							</PrivateRoute>
							<PrivateRoute exact path="/octg/home">
								<OctgHome />
							</PrivateRoute>
							<PrivateRoute exact path="/octg/view/actual-data">
								<OctgViewActualData />
							</PrivateRoute>
							<PrivateRoute exact path="/octg/view/actual-data/:loadId">
								<OctgViewActualData />
							</PrivateRoute>
							<PrivateRoute exact path="/octg/view/nominal-data">
								<OctgViewNominalData />
							</PrivateRoute>
							<PrivateRoute exact path="/octg/view/nominal-data/:loadId">
								<OctgViewNominalData />
							</PrivateRoute>
							<PrivateRoute exact path="/octg/upload/actual-data">
								<OctgUploadActualData />
							</PrivateRoute>
							<PrivateRoute exact path="/octg/upload/nominal-data">
								<OctgUploadNominalData />
							</PrivateRoute>
							<PrivateRoute exact path="/project-plpp-data">
								<ProjectAndMaterialData />
							</PrivateRoute>
							<PrivateRoute exact path="/plpp-data">
								<PlppData />
							</PrivateRoute>
							<PrivateRoute path="/">
								<Redirect to="/" />
							</PrivateRoute>
						</Switch>
					</PrivateRoute>
				</Switch>
			</BrowserRouter>
		</div>
	)
}

export default Router
